.shows-container {
    padding: 0rem 3vw 0.5rem 3vw;
    position: relative;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.shows-container h3 {
    position: absolute;
    font-weight: 500;
    padding-left: 2vw;
    padding-top: 0.5rem;
}

.shows-row {
    padding: 2vw;
    padding-top: 2.25rem;
    padding-bottom: 1.5rem;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.shows-row::-webkit-scrollbar {
    display: none;
}

.shows-link {
    border-radius: 0.1rem;
    height: 15rem;
    width: min-content;
    margin: 0.5rem 0px;
    margin-right: 0.5rem;
    transition: transform 0.3s ease 100ms, box-shadow 0.3s ease 100ms;
    box-shadow:
        0 0px 2.7px rgba(0, 0, 0, 0.006),
        0 0px 6.9px rgba(0, 0, 0, 0.009),
        0 0px 14.2px rgba(0, 0, 0, 0.011),
        0 0px 29.2px rgba(0, 0, 0, 0.014),
        0 0px 80px rgba(0, 0, 0, 0.02);
    cursor: pointer;
}

.show-img {
    height: 15rem;
    border-radius: 0.25rem;
    width: auto;
}

.shows-container:hover .shows-link {
    transform: translateX(-5%);
}

.shows-link:hover~.shows-link {
    transform: translateX(5%);
}

.shows-link:hover,
.shows-link:focus {
    transform: scale(1.15) !important;
    box-shadow:
        0 0px 2.7px rgba(0, 0, 0, 0.037),
        0 0px 6.9px rgba(0, 0, 0, 0.053),
        0 0px 14.2px rgba(0, 0, 0, 0.067),
        0 0px 29.2px rgba(0, 0, 0, 0.083),
        0 0px 80px rgba(0, 0, 0, 0.12);
}

.mob__slider {
    padding: 0px !important;
    margin: 0px !important;
}


@media screen and(max-width: 700px) {
    .shows-container {
        padding: 0rem 3vw;
        margin-top: 0px;
        width: 100vw;
    }

    .shows-row {
        padding: 2vw;
        padding-top: 2.25rem;
        padding-bottom: 1rem;
    }

    .shows-link {
        border-radius: 0.4rem;
        margin-right: 0.75rem;
        height: 35vh;
    }

    .show-img {
        height: 35vh;
    }
}