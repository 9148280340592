.slider__holder {
    margin-top: -9vh;
    background-image: linear-gradient(180deg, var(--background) 1%, var(--black-grey) 20%);
    padding-bottom: 3rem;
}

@media screen and (max-width:700px) {
    .slider__holder {
        margin-top: 0px;
        padding-top: 1.5rem;
        padding-bottom: 2rem;
        background-color: var(--black-grey);
        background-image: none;
    }
}