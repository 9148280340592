.slide-button {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3.5rem;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  outline: 0;
  padding: 0;
  margin: 4rem 0 2.25rem 0;
  z-index: 15;

  span {
    width: 25px;
    color: #fff;
    display: block;
    margin: 0 auto;
  }

  &--next {
    right: 0;

    span {
      transform: rotateZ(-90deg);
    }
  }

  &--prev {
    left: 0;

    span {
      transform: rotateZ(90deg);
    }
  }
}

.slide-button:hover {
  background-color: rgba(256, 256, 256, 0.9);
}

.slide-button:hover span {
  color: var(--primary-color) !important;
}