@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root {
  --primary-color: #F40612;
  --background: #000000;
  --black-grey: #181818;
  --text: #ffffff;
  --grey: #757575;
}

html {
  scroll-behavior: smooth;
  transition: scroll-behavior 0.25s ease-in-out;
}

body {
  margin: 0;
  position: relative;
  font-family: 'Roboto', sans-serif;
  width: 100vw;
  overflow-x: hidden;
  background-color: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  opacity: 1;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.4s ease-in-out, filter 0.4s ease-in-out, border 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

*:focus {
  outline: 0px;
}

h1,
h2,
h3,
h4,
p,
a {
  margin: 0;
  padding: 0;
  color: var(--text);
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
  padding: 0.5rem 0rem;
}

h3 {
  font-size: 1.4rem;
}

h4 {
  font-size: 1.2rem;
}

p {
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem 0rem;
}

a {
  font-weight: 500;
}

button {
  cursor: pointer;
}