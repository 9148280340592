.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem 0px;

    span {
        font-weight: 700;
        color: var(--text);
    }

    p {
        padding: 0px;
    }
}

.watermark {
    text-align: center;
    font-size: 0.9rem;
    color: var(--grey);
}