.disclaimer {
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    justify-content: center;
}

.disclaimer-content {
    margin-top: auto;
    padding: 1rem 5vw 0.5rem 5vw;
    font-weight: 500;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1;
}

.disclaimer-btn {
    padding: 0.5rem 1rem;
    border-radius: 2.5rem !important;
    border: 0px !important;
    font-size: 0.9rem !important;
    font-weight: 500 !important;
    color: var(--text) !important;
    background-color: var(--background) !important;
    margin-bottom: auto !important;
}

.copyright {
    padding: 0rem 5vw;
    text-align: center;
    padding-bottom: 0.75rem;
}