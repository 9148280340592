.content {
  position: relative;
  height: 37vw;
  margin-top: -40px;

  &__background,
  &__background__shadow,
  &__background__image,
  &__area {
    position: absolute;
    top: 0;
    bottom: 0;
  }

  &__background {
    left: 0;
    right: 0;

    &__shadow {
      left: 0;
      background: #000;
      width: 30%;
      z-index: 2;
      height: 105%;

      &:before {
        content: '';
        position: absolute;
        z-index: 10;
        background-image: linear-gradient(to right, #000, transparent);
        top: 0;
        bottom: 0;
        left: 100%;
        width: 275px;
      }
    }

    &__image {
      right: 0;
      width: 70%;
      height: 105%;
      background-size: cover;
      background-position: left;
      background-repeat: no-repeat;
      z-index: 1;
    }
  }

  &__area {
    left: 0;
    right: 0;
    height: 100%;
    z-index: 3;

    &__container {
      padding: 2rem 4.5rem;
      width: 60%;
      line-height: 1.25;
    }
  }

  &__title {
    font-size: 3rem;
    color: #fff;
    font-weight: 700;
  }

  &__description {
    font-size: 1.1rem;
    color: #fff;
    opacity: 0.7;
    margin-top: 1rem;
    max-width: 500px;
  }

  &__close {
    color: #fff;
    width: 1.75rem;
    height: 1.75rem;
    background: transparent;
    outline: none;
    border: none;
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

@media screen and(max-width:700px) {
  .content__background__image {
    width: 100%;
  }
}